<template>
  <div>
    <!-- Alert Input -->
    <BRow v-if="contentState.type !== 'saldo' && contentState.type !== 'validation'" class="p-1">
      <BCol cols="12" class="mb-2">
        <h4 class="text-black mb-1">
          <strong>
            {{ contentState.title }}
          </strong>
        </h4>
        <span class="text-black">
          {{ contentState.content }}
        </span>
      </BCol>
      <BCol cols="12" class="text-right">
        <BButton variant="primary" class="done-btn" @click="hide">
          <strong>
            Oke
          </strong>
        </BButton>
      </BCol>
    </BRow>

    <!-- Alert Saldo -->
    <BRow v-if="contentState.type === 'saldo'" class="p-1 m-1">
      <BCol cols="12" class="d-flex justify-content-center text-center mb-2">
        <BImg src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png" />
      </BCol>
      <BCol cols="12" class="d-flex justify-content-center text-center mb-2">
        <div>
          <h4 class="text-black">
            <strong>
              Saldo Belum Mencukupi
            </strong>
          </h4>
          <span class="text-black">
            Kamu harus mengisi saldo dulu ya, sebelum membuat order dengan metode Transfer Bank
          </span>
        </div>
      </BCol>
      <BCol cols="12" class="d-flex justify-content-center">
        <BButton variant="outline-primary" class="saldo-btn mr-1" @click="hide('saldo')">
          <strong>
            Kembali
          </strong>
        </BButton>
        <BButton variant="primary" class="saldo-btn" @click="moveTopup">
          <strong>
            Top up Saldo
          </strong>
        </BButton>
      </BCol>
    </BRow>

    <!-- Alert Validation -->
    <BRow v-if="contentState.type === 'validation'" class="p-1 m-1">
      <BCol cols="12" class="d-flex justify-content-center mb-2">
        <BImg
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
      </BCol>
      <BCol cols="12" class="mb-2 text-center">
        <span class="text-black">
          {{ contentState.titleAction }}
        </span>
      </BCol>
      <BCol cols="12" class="text-center">
        <BButton variant="primary" class="shipment-btn" @click="movePage(contentState.actionState)" :style="contentState.actionState === 'Tambahkan Alamat Pickup' ? 'width: 260px;' : ''">
          <strong>
            {{ contentState.actionState }}
          </strong>
        </BButton>
      </BCol>
    </BRow>
  </div>
</template>

<script>
export default {
  props: ['contentState'],
  methods: {
    hide(state) {
      this.$emit('hideModal')
      if (state === 'saldo') window.location.reload()
    },
    movePage(state) {
      if (state === 'Aktifkan Ekspedisi') {
        this.$router.push({ name: 'kompship-ekspedisi' })
      }
      if (state === 'Tambah Produk') {
        this.$router.push({ name: 'add-produk' })
      }
      if (state === 'Tambahkan Alamat Pickup') {
        this.$router.push({ name: 'gudangku' })
      }
    },
    moveTopup() {
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>

<style scoped>
  .done-btn {
    width: 120px;
    height: 45px;
    border-radius: 12px;
  }

  .saldo-btn {
    width: 170px;
    height: 45px;
    border-radius: 8px;
  }

  .shipment-btn {
    width: 200px;
    height: 45px;
    border-radius: 8px;
    letter-spacing: 1px;
  }
</style>
